import '../stylesheets/application';
import './bootstrap_custom.js';

import "@fortawesome/fontawesome-free/js/all";
import './font_awesome.js';
import './css-doodle.js'

global.iziToast = require('izitoast');


iziToast.settings({
  timeout: 10000,
  resetOnHover: false,
  icon: 'material-icons',
  transitionIn: 'flipInX',
  transitionOut: 'flipOutX',
  position: 'topRight',
  closeOnClick: true
});
